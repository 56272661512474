<template>
  <el-row :gutter="20">
    <el-col :xs="20" :md="12" class="flex">
      <!-- Phone type -->
      <el-form-item
        prop="phone_type"
        class="mr-4 mb-3 flex-shrink-0"
        :label="isFirstRow ? $t('common.forms.labels.phonetype') : null"
      >
        <el-select
          id="phone_type"
          v-model="form.phone_type"
          class="w-full"
          :placeholder="$t('common.inputs.placeholders.select')"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="phone in phoneTypes"
            :key="phone.value"
            :value="phone.value"
            :label="phone.label"
            :disabled="phone.disabled"
          />
        </el-select>
      </el-form-item>

      <!-- Phone number -->
      <el-form-item
        prop="phone_number"
        :label="isFirstRow ? $t('common.forms.labels.phonenumber') : null"
        class="flex-1 mb-3"
      >
        <el-input
          id="phone_number"
          v-model="form.phone_number"
          class="w-full"
        />
      </el-form-item>
    </el-col>
    <el-col :xs="4" :md="12">
      <div :class="{ 'mt-th-input-title': isFirstRow }">
        <!-- Delete -->
        <el-button
          icon="Delete"
          class="-ml-3 el-button--text-icon"
          :title="$t('common.interactions.buttons.remove')"
          @click="$emit('remove-item')"
        />

        <!-- Add -->
        <el-button
          v-if="isLastRow"
          class="el-button--primary-icon"
          :title="$t('common.interactions.buttons.add')"
          plain
          icon="Plus"
          :disabled="canAdd"
          @click="$emit('add-item')"
        />
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    isFirstRow: {
      type: Boolean,
      default: false
    },
    isLastRow: {
      type: Boolean,
      default: false
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    usedPhoneTypes: {
      type: Array,
      default: () => []
    },
    allowedPhoneTypes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    translations() {
      return {
        any: this.$t(
          'pages.customers.edit.form.properties.phonenumbers.types.main.label'
        ),
        main: this.$t(
          'pages.customers.edit.form.properties.phonenumbers.types.main.label'
        ),
        home: this.$t(
          'pages.customers.edit.form.properties.phonenumbers.types.home.label'
        ),
        work: this.$t(
          'pages.customers.edit.form.properties.phonenumbers.types.work.label'
        ),
        mobile: this.$t(
          'pages.customers.edit.form.properties.phonenumbers.types.mobile.label'
        )
      }
    },
    phoneTypes() {
      return this.allowedPhoneTypes.map((value) => {
        return {
          value,
          label: this.translations[value],
          disabled: this.usedPhoneTypes.includes(value)
        }
      })
    }
  }
}
</script>
