<template>
  <div>
    <div v-for="(item, index) in form" :key="index">
      <phones-inputs-row
        v-model="form[index]"
        :is-first-row="index === 0"
        :is-last-row="index === form.length - 1"
        :can-add="showAddButton"
        :used-phone-types="usedPhoneTypes"
        :allowed-phone-types="phoneTypes"
        @add-item="addItem"
        @remove-item="removeItem(index)"
      />
    </div>
  </div>
</template>

<script>
import PhonesInputsRow from './phones-inputs-row'
import { genInitialPhone } from './helpers'

export default {
  components: {
    PhonesInputsRow
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    phoneTypes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue || []
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    showAddButton() {
      return this.usedPhoneTypes.length > this.phoneTypes.length - 1
    },
    usedPhoneTypes() {
      return this.form.map(({ phone_type }) => phone_type)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        if (!this.form.length) this.addItem()
      }
    }
  },
  methods: {
    removeItem(index) {
      this.form.splice(index, 1)
      if (!this.form.length) this.addItem()
    },
    addItem() {
      this.form.push(genInitialPhone())
    }
  }
}
</script>
